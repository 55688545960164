<template lang="pug">
	.catalog
		h2.mb-4 {{ $t('menu.catalog') }}

		b-tabs(nav-class='tabs-filled tabs-filled-transparent' v-model='tab' no-fade)
			b-tab(:title="$t('merchantPartners.myProjects')")
				ProjectList(:items="projects" @getProjects="getProjects" v-if="tab===0" :isLoading="isLoading")
			b-tab(:title="$t('merchantPartners.archive')")
				ProjectList(:items="projects" :isArchived="true" @getProjects="getProjects" v-if="tab===1" :isLoading="isLoading")

</template>
<script>
import ProjectAlerts from '@/components/Cards/ProjectAlerts.vue';
import ProjectList from '@/components/Cards/ProjectList.vue';
import { mapActions, mapGetters } from 'vuex';
import { tablePerPage as tablePerPageNumber } from '@/constants/config';

export default {
    components: { ProjectList, ProjectAlerts },
    data: () => ({
        tab: 0,
        isLoading: false,
        projects: [],
    }),
    metaInfo() {
        return { title: this.$t('menu.catalog') };
    },
    created() {
        this.getProjects({ itemsPerPage: { value: tablePerPageNumber } });
    },
    methods: {
        ...mapActions({
            get: 'merchantPartner/get',
        }),
        async getProjects(filters = null) {
            this.isLoading = true;
            let params = {
                isArchived: this.tab,
            };
            for (const key in filters) {
                if (filters[key]) {
                    params[key] = filters[key]?.value ?? filters[key];
                    if (!params[key].from) delete params[key].from;
                    if (!params[key].to) delete params[key].to;
                }
            }

            if (params?.append) delete params.append;
            const items = await this.get(params);
            if (filters?.append) {
                this.projects = [...this.projects, ...items];
            } else {
                this.projects = items;
            }

            this.isLoading = false;
        },
    },
    computed: {},
    watch: {
        tab() {
            this.projects = [];
            this.getProjects();
        },
    },
};
</script>
