<template lang="pug">
	b-alert.card-copy-link(variant='primary' show)
		.d-flex.justify-content-between
			p.f-16.mb-2 {{ title }}
			.icons-wrapper.d-none.d-sm-block
				i.text-primary.fontello.fontello-icon-edit.cursor-pointer.mr-4(v-if='canEdit' @click='openEditLink')
				i.text-primary.fontello.fontello-icon-copy.cursor-pointer(
					v-if='canCopy'
					v-clipboard:copy="paymentLink"
					v-clipboard:success="onCopy"
					v-clipboard:error="onCopyError")
		p.mb-2.text-muted {{ desc }}
		a.text-break.d-block(:href='paymentLink' target='_blank') {{ paymentLink }}
		.mt-4.icons-wrapper.d-sm-none
			i.text-primary.fontello.fontello-icon-edit.cursor-pointer.mr-4(@click='openEditLink')
			i.text-primary.fontello.fontello-icon-copy.cursor-pointer(
				v-if='canCopy'
				v-clipboard:copy="paymentLink"
				v-clipboard:success="onCopy"
				v-clipboard:error="onCopyError")
</template>

<script>
import { mapGetters } from 'vuex';
import { bus } from '@/main.js';

export default {
    name: 'LinkCard',
    props: {
        title: { type: String, default: bus.$t('project.link') },
        desc: { type: String, default: bus.$t('project.linkDescription') },
        link: { type: String, default: null },
        canCopy: { type: Boolean, default: true },
        canEdit: { type: Boolean, default: true },
    },
    data: () => ({}),

    computed: {
        ...mapGetters({
            item: 'project/opened',
        }),

        paymentLink() {
            if (this.link != null) return this.link;

            return this.item ? `${this.paymentLinkDomain}${this.item.hashIdCustom}` : '';
        },

        paymentLinkDomain() {
            if (this.link != null) return this.link;

            return `${window.location.protocol}//${window.location.host.replace('merchant.', '')}/`;
        },
    },

    methods: {
        copy() {
            this.$copyText(this.paymentLink)
                .then(e => this.$notify('success', this.$t('success.copyLink')))
                .catch(e => this.$notify('error', this.$t('error.copyLink'), e));
        },

        openEditLink() {
            this.$emit('openEditLink', this.item);
        },
        onCopy() {
            this.$notify('success', this.$t('success.copyLink'));
        },
        onCopyError() {
            this.$notify('error', this.$t('error.copyLink'));
        },
    },
};
</script>

<style lang="scss" scoped>
.card-copy-link {
    padding: 16px !important;
}
</style>
