<template lang="pug">
	.project
		b-card.card-project.mb-4(no-body)
			img.card-img-top(v-if='item.image' :src='item.image.contentUrl' :alt='item.title')

			b-card-body
				h3.mb-4 {{ item.title }}
				p.project-description.mb-0.lh-18(v-html='description' :id='`desc${item.id}`' :class="descClass")
				.expand-collapse.mt-2(v-if='needExpand' :class="{'expanded' : isDescriptionExpanded}" @click='expand()')
					span(v-if='isDescriptionExpanded') {{ $t('forms.collapse') }}
					span(v-else) {{ $t('forms.expand') }}

		.container-xs(v-if='!onlyCommonData')
			link-card.mb-4(@openEditLink='openEditLink' v-if='hidePlansForMobile && isMobile && item.hasPaymentSystem()')

			.text-center.mb-4(v-if='hideEmptyPlans')
				router-link(:to="{name: 'find'}" target='_blank')
					b-btn(variant='outline-primary') {{ $t('h1.find') }}
</template>

<script>
import LinkCard from '@/components/Cards/Link';
import Flickity from 'vue-flickity';
import $ from 'jquery';
import { ENV } from '@/api/index';

const MAX_DESCRIPTION_LENGTH = 500;

export default {
    name: 'ProjectCard',
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        hideEmptyPlans: {
            type: Boolean,
            default: false,
        },
        onlyCommonData: {
            type: Boolean,
            default: false,
        },
        hidePlansForMobile: {
            type: Boolean,
            default: false,
        },
        showTrialTitleForPlan: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        flickityOptions: {
            initialIndex: 0,
            prevNextButtons: false,
            pageDots: false,
            groupCells: 1,
        },
        showPlans: false,
        isDescriptionExpanded: false,
        descClass: {},
        rowsVisible: -1,
    }),
    components: {
        Flickity,
        LinkCard,
    },

    mounted() {
        this.$nextTick(() => {
            this.resize();
        });

        let f = $('#carousel');

        f.on('dragStart.flickity', () => {
            document.ontouchmove = e => e.preventDefault();
        }).on('dragEnd.flickity', () => (document.ontouchmove = () => true));
    },

    beforeDestroy() {},

    computed: {
        sortedPlans() {
            return this.item.id
                ? _.sortBy(
                      _.sortBy(
                          _.filter(this.item.plans, i => !i.isArchived()),
                          i => Number(i.price.amount)
                      ),
                      'price.currency'
                  )
                : [];
        },

        description() {
            if (!this.item || !this.item.description) return '';

            let desc = this.item.description.replace(
                /(https?\:\/\/[^\s]*)/gi,
                '<a class="text-primary" target="_blank" href="$1">$1</a>'
            );

            if (desc.length > MAX_DESCRIPTION_LENGTH && !this.isDescriptionExpanded)
                return desc.substring(0, MAX_DESCRIPTION_LENGTH) + '...';

            return desc;
        },

        needExpand() {
            if (!this.item || !this.item.description) return false;

            this.calculateRowsForDescription();
            return this.rowsVisible <= 0;
            // return this.description.length > MAX_DESCRIPTION_LENGTH;
        },

        trial() {
            return _.find(this.item.plans, s => s.isTrial == true && s.isActive());
        },
    },

    methods: {
        updateDescription() {
            // let desc = this.item.description;
            // this.item.description = '';
            // this.$nextTick(() => {
            // 	// this.item.description = desc;
            // 	setTimeout(() => {
            // 		// this.isDescriptionExpanded = true;
            // 		// this.calculateRowsForDescription();
            // 		// this.isDescriptionExpanded = false;
            // 		this.rowsVisible = 500
            // 	}, 100)
            // });
        },
        select(plan, isTrial) {
            this.$emit('selected', { plan: plan, isTrial: isTrial });
        },

        goToEdit(plan) {
            let id = plan ? plan.id : null;

            if (!plan && this.item.plans.length == 1) {
                id = this.item.plans[0].id;
            }

            this.$router.push({ name: 'edit_project', params: { id: this.item.id }, query: { tab: 2, plan: id } });
        },
        resize() {
            if (this.$refs.flickity) this.$refs.flickity.resize();
        },

        openEditLink() {
            this.$emit('openEditLink', true);
        },
        expand() {
            this.isDescriptionExpanded = !this.isDescriptionExpanded;
        },
        calculateRowsForDescription() {
            if (this.isDescriptionExpanded) {
                delete this.descClass['text-6-row'];
                this.rowsVisible = -1;
                return -1;
            }

            let defaultDescRow = 6;
            let rowsTitle = this.calcRowsForText(`desc${this.item.id}`, 18);

            if (rowsTitle > 6) this.descClass['text-6-row'] = true;

            this.rowsVisible = defaultDescRow - (rowsTitle - 1);
            return defaultDescRow - (rowsTitle - 1);
        },
        calcRowsForText(id, lineHeight) {
            let el = document.getElementById(id);

            if (el == null) return;

            let lines = Math.ceil(el.offsetHeight / lineHeight);

            return lines == 0 ? 1 : lines;
        },
    },

    created() {
        this.descClass = { 'project-description-short': !this.isDescriptionExpanded && this.needExpand };
        setTimeout(this.calculateRowsForDescription, 1000);
    },

    watch: {},
};
</script>

<style lang="scss">
.card-project {
    .card-body {
        padding-top: 16px !important;
    }
}

@media (max-width: 575.98px) {
    .project {
        overflow: hidden;
    }
    .card-project {
        .card-body {
        }
    }
}
</style>
