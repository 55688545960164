<template lang="pug">
	b-dropdown.dropdown.b3.dropdown-general.animated(v-if="list" :text='selected' :class="[size, {'not-chosen' : notChosen && selected == list[0].title}, icon]" menu-class='p-0 m-0 b3' :disabled='disabled')
		template(v-slot:button-content)
			.direction(@click.stop.prevent="changeDirection" :class="direction")
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><g clip-path="url(#clip0_31498_35814)"><path d="M8.83915 15.6092C8.44485 16.2163 7.55619 16.2163 7.1619 15.6092L4.26145 11.1437C3.82934 10.4784 4.30679 9.599 5.10008 9.599H10.901C11.6943 9.599 12.1717 10.4784 11.7396 11.1437L8.83915 15.6092Z" fill="#CBD2D8"/><path d="M7.16085 1.39076C7.55515 0.783701 8.44381 0.783701 8.8381 1.39076L11.7386 5.85629C12.1707 6.52156 11.6932 7.401 10.8999 7.401H5.09903C4.30575 7.401 3.8283 6.52156 4.2604 5.85629L7.16085 1.39076Z" fill="#8292A1"/></g><defs><clipPath id="clip0_31498_35814"><rect width="16" height="16" fill="white" transform="translate(0 0.5)"/></clipPath></defs></svg>
			.selected(v-html="selected")
		b-dropdown-item(v-for='(item, i) in list' :key='i' @click="selected = String(item.title), $emit('onChange', notChosen ? selected : (fullItem ? item : item.id))" )
			span(v-if="item.title" v-html="item.title")
			svg.ml-3(v-if="selected == item.title" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg")
				path(d="M2.29883 9.24023L5.68129 12.9609L14.7012 3.03906" stroke="#59CB94" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
</template>

<script>
export default {
    name: 'DropdownGeneral',
    props: {
        list: {
            default: () => [],
            type: Array,
        },
        size: {
            default: '',
            type: String,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        notChosen: {
            default: false,
            type: Boolean,
        },
        fullItem: {
            default: false,
            type: Boolean,
        },
        selecteDefault: {
            default: 0,
            type: [Number, String],
        },
        icon: {
            default: '',
            type: String,
        },
    },
    data: () => ({
        directionList: ['asc', 'desc'],
        direction: 'asc',
        selected: '',
    }),
    created() {
        this.defaultSelected();
    },
    methods: {
        defaultSelected() {
            this.selected = this.list[this.selecteDefault].title;
        },
        changeDirection() {
            this.direction = this.directionList.find(itm => itm !== this.direction);
            this.$emit('changeDirection', this.direction);
        },
    },
    watch: {
        selecteDefault(v) {
            this.defaultSelected();
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.dropdown-general {
    .selected {
        border-left: 1px solid var(--border-dropdown-color);
        padding-left: 10px;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .direction {
        display: flex;
        align-items: center;
        transition: all 0.3s ease;

        margin: -15px;
        padding: 15px;
        &.desc {
            transform: scale(1, -1);
        }
    }

    .dropdown-toggle {
        &::after {
            min-width: 18px;
        }
    }

    .dropdown-menu {
        right: auto !important;

        @include respond-below(md) {
            width: 100%;
        }
    }
}
</style>
